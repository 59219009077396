import SliderModule from "@components/SliderModule/SliderModule"
import TeamCard from "@components/TeamCard/TeamCard"
import useTeams from "@hooks/dataHooks/useTeams"
import React from "react"
import "./TeamsSlider.scss"

const TeamsSlider = ({ module, teams }) => {
  const allTeams = useTeams()
  if (!module) return null

  const { title, heading, cta_text } = module

  const teamsToDisplay = teams || allTeams
  const filteredTeams = teamsToDisplay?.filter((team) => team?.publish)

  const sliderChildren = filteredTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} />)

  return (
    <SliderModule
      heading={heading}
      title={title}
      ctaText={cta_text}
      sliderChildren={sliderChildren}
      wrapperClassname="teams-slider-wrap"
      size="xl"
      sectionClassname="section-m"
    />
  )
}

export default TeamsSlider
