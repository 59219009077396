import OffplanCard from "@components/OffplanCard/OffplanCard"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useLimitOffplans from "@hooks/dataHooks/useLimitOffplans"
import useLimitProperties from "@hooks/dataHooks/useLimitProperties"
import {
  offplanResultsSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
} from "@lib/urls"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import "./FeaturedPropertiesSlider.scss"
import useDeviceMedia from "@hooks/useDeviceMedia"
import {  getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils" 
import { getOrderedImages } from "@lib/utils"
const FeaturedPropertiesSlider = ({ module, properties, offplans, hideoffplan, morelink }) => {
  const { isMobile } = useDeviceMedia()

  const KEYS = useMemo(() => {
    return {
      sale: "for-sale",
      rent: "for-rent",
      offplan: "off-plan",
    }
  }, [])

  const [activeKey, setActiveKey] = useState(KEYS.sale)

  const { propertiesForSale, propertiesToLet } = useLimitProperties(properties)
  const { offplans: offplansToShow } = hideoffplan ? [] : useLimitOffplans(offplans)

  const [propertiesToShow, setPropertiesToShow] = useState(propertiesForSale)

  useEffect(() => {
    if (isEmpty(propertiesForSale) && isEmpty(propertiesToLet)) {
      setActiveKey(KEYS.offplan)
      setPropertiesToShow(offplansToShow)
    } else if (isEmpty(propertiesForSale)) {
      setActiveKey(KEYS.rent)
      setPropertiesToShow(propertiesToLet)
    } else if (isEmpty(propertiesToLet)) {
      setActiveKey(KEYS.sale)
      setPropertiesToShow(propertiesForSale)
    }
  }, [KEYS.offplan, KEYS.rent, KEYS.sale])


  const [hideSaleTab, setSaleHideTab] = useState(false)
  const [hideLetTab, setLetHideTab] = useState(false)
  const [hideOffplanTab, setOffplanHideTab] = useState(false)

  useEffect(() => {
    let path = window?.location?.pathname
    if (path === "/property-buying-dubai-guide/" || path === "/property-selling-dubai-guide/") {
      setActiveKey(KEYS.sale)
      setLetHideTab(true)
      setOffplanHideTab(true)
      setPropertiesToShow(propertiesForSale)
    }
    else if (path === "/property-renting-dubai-guide/") {
      setActiveKey(KEYS.rent)
      setSaleHideTab(true)
      setOffplanHideTab(true)
      setPropertiesToShow(propertiesToLet)
    }
    else if (path === "/offplan-property-buying-dubai-guide/") {
      setActiveKey(KEYS.offplan)
      setSaleHideTab(true)
      setLetHideTab(true)
      setPropertiesToShow(offplansToShow)
    }


  }, [])

  const onChange = (key) => {
    if (key === KEYS.sale) {
      setPropertiesToShow(propertiesForSale)
    } else if (key === KEYS.rent) {
      setPropertiesToShow(propertiesToLet)
    } else {
      setPropertiesToShow([])
    }
  }

  if (!module) return null

  const { title, heading } = module
  let moreUrl = propertiesSaleURL

  if (activeKey === KEYS.rent) {
    moreUrl = propertiesRentURL
  } else if (activeKey === KEYS.offplan) {
    moreUrl = offplanResultsSaleURL
  }


  if (hideoffplan) {
    moreUrl = propertiesSaleURL + `in-${morelink}`

    if (activeKey === KEYS.rent) {
      moreUrl = propertiesRentURL + `in-${morelink}`
    } else if (activeKey === KEYS.offplan) {
      moreUrl = offplanResultsSaleURL + `in-${morelink}`
    }
  }
  const [userObjects, setUserObjects] = useState({});
  useEffect(() => {
    if (isAuthenticated()) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              
          }
      }
      getUserObjects()
    }
  }, [])

  const CTA = (
    <Link className="button button-orange more-btn" to={moreUrl}>
      {/* More {activeKey === KEYS.offplan ? "Projects" : "Properties"} */}
      {isMobile ? `View more ${activeKey === KEYS.offplan ? "Projects" : "Properties"}` : `View more`}
    </Link>
  )

  return (
    <Animation
      animateInType="up"
      className="featured-slider-module-wrap section-m"
    >
      <Container className="featured-slider-module-container">
        <div className="content-section tiv">
          {heading && (
            <p className="heading">
              <span>{heading}</span>
            </p>
          )}
          <h2 className="title">
            <span>{title}</span>
          </h2>
        </div>
        <div className="featured-slider-tab-section">
          <Tabs
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            className="tab-header"
            onChange={onChange}
            CTA={CTA}
          >
            {!!propertiesForSale?.length && !hideSaleTab && (
              <Tab eventKey={KEYS.sale} title="For Sale">
                For Sale
              </Tab>
            )}
            {!!propertiesToLet?.length && !hideLetTab && (
              <Tab eventKey={KEYS.rent} title="For Rent">
                For Rent
              </Tab>
            )}
            {!!offplansToShow?.length && !hideOffplanTab && (
              <Tab eventKey={KEYS.offplan} title="Off Plan">
                Off Plan
              </Tab>
            )}
          </Tabs>

          <div className="tab-body">
            <Slider key={`tab${activeKey}`} size="sm">

              {activeKey === "off-plan"
                ? offplansToShow?.length &&
                offplansToShow
                  .slice(0, 6)
                  .map((offplan) => {
                    const imagesArray = getOrderedImages(offplan);
                    return (<OffplanCard
                      minsBeds={offplan.min_bedrooms}
                      maxBeds={offplan.max_bedrooms}
                      displayBedrooms={offplan.display_bedrooms}
                      buldingType={offplan.building_type}
                      image={offplan.tile_image ? offplan.tile_image : offplan?.media_images?.length > 0 ? offplan?.media_images[0] : offplan?.images?.length > 0 ? offplan?.images[0] : ""}
                      images2={imagesArray}
                      title={offplan.title}
                      developer={offplan.developer}
                      location={offplan.display_address}
                      slug={offplan.slug}
                      videoURL={offplan?.video_module?.video_url}
                      community={offplan?.community?.title}
                      key={offplan.id}
                      completionYear={offplan.completion_year}
                      price={offplan?.price}
                      slider
                      future_launch={offplan?.future_launch}
                      ggfx_results={offplan?.ggfx_results}
                      strapi_id={offplan?.strapi_id}
                      notalgo
                      property={offplan}
                    />)
                  }
                    
                  )
                : propertiesToShow.length &&
                propertiesToShow
                  .slice(0, 6)
                  .map((property, index) => (
                    <PropertyCard
                      key={property.id}
                      address={property.display_address}
                      ammenities={property.accommodation_summary}
                      description={property.description}
                      bathrooms={property.bathroom}
                      bedrooms={property.bedroom}
                      building={property.building}
                      image={property.images?.[0]}
                      images={property.images}
                      price={property.price}
                      size={property.floorarea_min}
                      index={index}
                      crmID={property?.crm_id}
                      bitrix_id={property?.extra?.bitrix_id}
                      slug={property?.slug}
                      email={property?.crm_negotiator_id?.email}
                      crm_negotiator_id={property?.crm_negotiator_id?.id}
                      name={property?.crm_negotiator_id?.name}
                      searchType={property?.search_type}
                      imagetransforms={property?.ggfx_results}
                      strapiID={property?.strapi_id}
                      department={property?.department}
                      phone={property?.crm_negotiator_id?.phone}
                      slider
                      singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
                      userObjects={userObjects}
                      link_to_employee={property?.link_to_employee}
                      imageCount={property?.images?.length}
                    />
                  ))}
               <div className="property-card-wrapper more-box"><div className="property-card">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="56" viewBox="0 0 66 56" fill="none">
                    <path d="M40.5731 37.363V48.8286M12.6697 48.8286V1.22217H30.2206M57.4561 21.1479L62.15 22.3909V38.6246M6.84413 27.7712H1V48.8286H50.4432M21.037 48.8286V40.3129H32.2243V48.8286" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22.5026 12.5389H16.8625V5.78564H25.3041M22.5026 23.9859H16.8625V17.2327H21.074M36.009 35.4145H16.8625V28.6612H25.3041M6.84404 34.6538H1.2782M1.2782 41.1658H6.84404" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.6" d="M49.6457 37.3639L45.6754 31.4827M53.0594 35.0634L49.2004 29.3306M63.5418 54.2099C62.0204 55.2303 59.9611 54.8407 58.9221 53.3193L48.6253 38.0689L54.1355 34.3398L64.4323 49.5902C65.4527 51.1115 65.0631 53.1709 63.5418 54.2099Z" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                    <path opacity="0.6" d="M39.6637 32.7435C47.9736 32.7435 54.7101 26.007 54.7101 17.6972C54.7101 9.38734 47.9736 2.65088 39.6637 2.65088C31.3539 2.65088 24.6174 9.38734 24.6174 17.6972C24.6174 26.007 31.3539 32.7435 39.6637 32.7435Z" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div className="price">{activeKey === KEYS.sale ? "Explore Thousands of Properties for Sale"
                  : activeKey === KEYS.rent ? "Additional Rental Opportunities Await"
                  :  activeKey === KEYS.offplan ? "Explore Further Off-Plan Opportunities" : ""}
                  {/* {activeKey === "off-plan" ? "projects" : "properties"} */}
                  </div>
                  <p>{activeKey === KEYS.sale ? "Browse Through Our Extensive Listings to Find Your Dream Home"
                  : activeKey === KEYS.rent ? "Browse a Comprehensive Selection of Rentals to Find the Perfect Fit for You"
                  :  activeKey === KEYS.offplan ? "Invest in Projects Thoughtfully Designed for Future Promise" : ""}</p>
                  {CTA}
                </div>
              </div></div>
            </Slider>
          </div>
        </div>
        {/* {isMobile && CTA} */}
        {/* {cta_text && (
          <>
            <div className="divider" />
            <CTAText data={cta_text} />
          </>
        )} */}
      </Container>
    </Animation>
  )
}

export default FeaturedPropertiesSlider
