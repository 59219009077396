import React from "react"
import "./OfficeLocation.scss"
import { GoogleMap, Marker } from "@react-google-maps/api"
import { mapStyles } from "@components/SearchResults/map/styles"
import markerIcon from "@images/icons/map-pin-circle.svg"
import useLoadMap from "@components/SearchResults/map/useLoadMap"

const OfficeLocation = ({ module }) => {
  const { isLoaded } = useLoadMap()

  if (!module?.offices || !isLoaded) return null

  const { offices } = module

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds()
    offices.map((office) => {
      return bounds.extend({
        lat: office.latitude,
        lng: office.longitude,
      })
    })

    offices.length > 1 && map.fitBounds(bounds)
  }

  return ( isLoaded?
    <div className="office-location-wrap">
      <div className="locations-map" id="map">
        <GoogleMap
          onLoad={handleOnLoad}
          // onClick={() => setActiveMarker(null)}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          zoom={14}
          center={
            offices?.length > 0 && {
              lat: offices[0].latitude,
              lng: offices[0].longitude,
            }
          }
          options={{
            styles: mapStyles,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {offices?.map((office) => {
            return (
              <div className="hybrid-marker" key={`${office.id}-marker`}>
                <Marker
                  position={{
                    lat: office.latitude,
                    lng: office.longitude,
                  }}
                  icon={markerIcon}
                />
              </div>
            )
          })}
        </GoogleMap>
      </div>
    </div> : null
  )
}

export default OfficeLocation
