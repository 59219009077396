import { graphql, useStaticQuery } from "gatsby"

const useTeams = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTeam(
        filter: { publish: { eq: true }, show_on_website: {eq: true} }
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          ...teamFragment
        }
      }
    }
  `)

  return data?.allStrapiTeam?.nodes
}

export default useTeams
