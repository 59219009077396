import { useJsApiLoader } from "@react-google-maps/api"

const useLoadMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyBSA2wLns25G7M-KF-zymVlW3tvjWPltGA",
    googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY,
    libraries:["places"]
  })

  return { isLoaded }
}

export default useLoadMap
