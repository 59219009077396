import CTALink from "@components/elements/CTALink"
import GGFXImage from "@components/elements/GGFXImage"
import Slider from "@components/elements/Slider/Slider"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./OurServices.scss"
import { parseHTML } from "@lib/utils"

const ServiceItem = ({ service, ggfx_results, strapiID }) => {
  const { cta, image, description, bg_color } = service

  const hasCta = !!cta?.custom_link || !!cta?.menu

 
  const Comp = hasCta ? CTALink : "div"

  return (
    <div className={`service-item ${bg_color}`}>
      <Comp cta={cta} className={clsx("img-section", hasCta && "img-zoom")}>
        {/* <img src={image?.url} alt={description} /> */}
        <GGFXImage
          ImageSrc={image}
          altText={description}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="page.image.service_img"
          strapiID={strapiID}
        />
      </Comp>
      <div className="content-section">
        {hasCta ? (
          <CTALink cta={cta} className="title" />
        ) : (
          <p className="title">{cta?.cta_label}</p>
        )}
        <p className="description">{description}</p>
      </div>
    </div>
  )
}

const OurServices = ({ module, ggfx_results, strapiID }) => {
  if (!module) return null
  const { title, services, heading, design_title } = module
  return (
    <div className="our-services-wrap section-m">
      <Container className="our-services-container">
        {heading && <p className="heading">{heading}</p>}
        {design_title && <div className="design_title">
          {parseHTML(design_title?.data?.design_title)}
          </div>}
        <h2 className="title">{title}</h2>
        <div className="services-section">
          <Slider className="services-slider" size="sm">
            {services?.map((service) => (
              <ServiceItem
                service={service}
                key={service.id}
                ggfx_results={ggfx_results}
                strapiID={strapiID}
              />
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default OurServices
