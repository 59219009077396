import React from "react"
import "./ContentAndStats.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"
import { isEmpty } from "lodash"
import Animation from "@components/elements/Animation"

const ContentAndStats = ({ module }) => {
  if (!module) return null

  const { heading, description, title, stats } = module
  return (
    <Animation className="content-and-stats-wrap section-m">
      <Container className="content-and-stats-container">
        <p className="heading">{heading}</p>
        <div className="content-section">
          <Animation animateInType="left">
            <p className="main-content">{title}</p>
          </Animation>
          <Animation animateInType="right" className="description">
            {parseHTML(description?.data?.description)}
          </Animation>
        </div>
        {!isEmpty(stats) && (
          <div className="stats-section">
            {stats?.map((stat, index) => (
              <Animation
                animateInType="up"
                delay={index * 200}
                wrapperClassName="stat-item"
                key={stats.id}
              >
                {stat.stat_value && (
                  <h3 className="value">{stat.stat_value}</h3>
                )}
                {stat.title && <p className="title">{stat.title}</p>}
                {stat.description && (
                  <p className="description">{stat.description}</p>
                )}
              </Animation>
            ))}
          </div>
        )}
      </Container>
    </Animation>
  )
}

export default ContentAndStats
