/* eslint-disable no-unsafe-optional-chaining */
import { graphql, useStaticQuery } from "gatsby"
import { isEmpty } from "lodash"

const useLimitProperties = (properties) => {
  const data = useStaticQuery(graphql`
    query {
      saleProps: allProperties(
        limit: 6
        sort: { fields: createdAt, order: DESC }
        filter: { publish: { eq: true }, status: { eq: "For Sale" } }
      ) {
        nodes {
          ...propertyCardFragment
        }
      }
      rentProps: allProperties(
        limit: 6
        sort: { fields: createdAt, order: DESC }
        filter: { publish: { eq: true }, status: { eq: "To Let" } }
      ) {
        nodes {
          ...propertyCardFragment
        }
      }
    }
  `)

  let propertiesForSale = data?.saleProps?.nodes
  let propertiesToLet = data?.rentProps?.nodes

  if (properties) {
    if (!isEmpty(properties)) {
      propertiesForSale = properties?.filter(
        (property) => property?.status === "For Sale"
      )
      propertiesToLet = properties?.filter(
        (property) => property?.status === "To Let"
      )
    } else {
      propertiesForSale = []
      propertiesToLet = []
    }
  }

  return {
    properties: [...propertiesForSale, ...propertiesToLet],
    propertiesForSale,
    propertiesToLet,
  }
}

export default useLimitProperties
